<template>
  <div>
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <a
          class="navbar-brand"
          href="index"
        >Atlantis-P</a>
        <div
          class="menu_btn collapsed"
          data-bs-toggle="collapse"
          data-bs-target="#navbarToggle"
        />
        <div
          id="navbarToggle"
          class="collapse navbar-collapse"
        >
          <ul class="navbar-nav me-auto">
            <li class="nav-item">
              <a
                class="nav-link"
                href="index"
              >Home</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="index#info"
              >Origin</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="index#roadmap"
              >Roadmap</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                href="index#team"
              >team</a>
            </li>
            <li class="nav-item social_link d-flex d-lg-none">
              <a
                href="https://discord.gg/ypU2e6S8dA"
                target="_blank"
              ><i class="fa-brands fa-discord" /></a>
              <a
                href="https://opensea.io/collection/atlantis-p"
                target="_blank"
              ><img
                src="img/opensea.png"
                class="icon_opensea"
                alt=""
              ></a>
            </li>
            <li class="nav-item d-flex d-lg-none">
              <a
                href="index_tw"
                class="link-light text-decoration-none m-auto"
              >
                中文
              </a>
            </li>
          </ul>
          <a
            v-if="isConnect"
            href="#"
            class="btn btn-primary btn_connect px-4 d-none d-lg-block"
          >Wallet Connected</a>
          <div class="social_link d-none d-lg-flex">
            <a
              href="https://discord.gg/ypU2e6S8dA"
              target="_blank"
            ><i class="fa-brands fa-discord" /></a>
            <a
              href="https://opensea.io/collection/atlantis-p"
              target="_blank"
            ><img
              src="img/opensea.png"
              class="icon_opensea"
              alt=""
            ></a>
          </div>
          <a
            href="index_tw"
            class="link-light text-decoration-none ms-3 d-none d-lg-flex"
          >
            中文
          </a>
        </div>
      </div>
    </nav>
    <!-- Connect Modal -->
    <div
      id="connectModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="connectModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h5
              id="connectModalLabel"
              class="modal-title"
            >
              Connect
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <ul class="wallet-list">
              <li
                data-bs-dismiss="modal"
                @click="upBtnConnect"
              >
                <img
                  src="img/metamask.png"
                  alt="metamask"
                >
                Meta Mask
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavList',
  props: {
    btnConnect: {
      type: String,
      default: 'Connect',
    },
  },
  data: () => ({
    isConnect: false,
  }),
  async mounted() {
    const provider = window.ethereum
    if (typeof provider !== 'undefined') {
      const myaccount = await provider.request({
        method: 'eth_accounts',
      })
      this.isConnect = myaccount[0] ? true : false
      provider.on('accountsChanged', (accounts) => {
        if (accounts.length === 0) {
          this.isConnect = false
        } else {
          this.isConnect = true
        }
      })
    }
  },
  methods: {
    async upBtnConnect() {
      const provider = window.ethereum
      if (typeof provider !== 'undefined') {
        const myaccount = await provider.request({
          method: 'eth_requestAccounts',
        })
        this.isConnect = myaccount[0] ? true : false
        this.$emit('handleCallBack', myaccount[0].slice(0, 6) + '...')
      } else {
        this.$toast.warning('MetaMask is not installed!')
      }
    },
  },
}
</script>

<style scoped></style>
